@import '../../../../shared/styles/core.css';


.advertisingCard {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 8px 8px 24px;
    border-radius: 0;
    background-color: rgba(238, 240, 251, 1);
    box-shadow: 2px 8px 15px 0 rgba(0, 0, 0, 0.15);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s;

    & .header {
        width: 100%;
        padding: 32px 0 0;

        @media (--newTablet) {

        }

        & .image {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            text-align: center;
        }

        & .image img {
            object-fit: contain;
            height: 60px;
            padding: 0;

            @media (--newTablet) {
                height: 70px;
            }

            @media (--newDesktopLarge) {
                height: 80px;
            }
        }

        & .image svg {
            height: 60px;
            padding: 0;

            @media (--newTablet) {
                height: 70px;
            }

            @media (--newDesktopLarge) {
                height: 80px;
            }
        }
    }

    & .content {
        padding: 0;

        & .title {
            display: block;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            @media (--newDesktopSmall) {
                font-size: 20px;
                line-height: 28px;
            }

            @media (--newDesktopLarge) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        & .description {
            margin: 24px 0 0;
        }
    }

    & .link {
        margin: 24px -4px 0;
        text-decoration: underline;
        text-transform: uppercase;
        text-decoration-style: dotted;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: var(--priority-primary-80);

        @media (--newDesktopSmall) {
            font-size: 16px;
            line-height: 22px;
        }
    }
}
