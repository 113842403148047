@import '../../../../../../src/shared/styles/core.css';

.sectionImage {
  display: flex;
  padding: 0;
  margin: 0;
  object-fit: contain;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 124px;

  @media (--s-xs) {
    height: 160px;
  }

  @media (--s-sm) {
    height: 240px;
  }

  @media (--s-md) {
    height: 240px;
  }

  @media (--s-lg) {
    height: 370px;
  }

  & img {
    max-width: 985px;
    width: 100%;
    height: 124px;

    @media (--s-xs) {
      height: 160px;
    }

    @media (--s-sm) {
      height: 240px;
    }

    @media (--s-md) {
      height: 240px;
    }

    @media (--s-lg) {
      height: 370px;
    }
  }
}
