@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 681px;

  & .last {
    padding-bottom: 16px;
  }
}

.review {
  padding: 24px 0;
}

@media (--screen-xs) {
  .review {
    padding: 32px 0;
  }
}

@media (--screen-lg) {
  .root {
    & .last {
      padding-bottom: 0;
    }
  }
}

@media (--screen-xl) {
  .root {
    max-width: 1005px;
  }
}
