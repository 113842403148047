@import '../../../../../../styles/core.css';


.items {
  min-height: 55px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0 calc(0 + env(safe-area-inset-bottom));
  border-top: 1px solid #F0F4F8;
  display: none;
  z-index: 200;
  font-family: Manrope, sans-serif;
  background-color: #FFFFFF;
  box-shadow: 0 4px 20px rgba(14, 22, 34, 0.15);
  box-sizing: border-box;

  @media (--desktopSmall) {
    display: flex;
  }
}

.item {
  width: 74px;
  height: 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  outline: none;
  border: none;
  padding: 8px 4px;
  margin: 0 4px;
  cursor: pointer;
  position: relative;
  background: none;
  text-decoration: none;
  box-sizing: border-box;

  & svg {
    color: var(--newColorTextSecondary);
  }

  &:hover,
  &.selected {
    & svg {
      color: var(--newColorTextAccent);
    }

    & .title {
      color: var(--newColorTextAccent);
    }
  }

  .title {
    color: var(--newColorTextSecondary);
    font-size: 10px;
  }

  .media {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.catalog .media {
  width: 54px;
}

.label {
  position: absolute;
  top: 4px;
  right: 20px;
  border: 2px solid #FFFFFF;
  background-color: var(--newColorNegative);
  color: var(--colorWhite);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
}
