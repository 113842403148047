@import '../../../../shared/styles/core.css';

.root {
  & :global {
    .modal__panel {
      gap: 16px;
      box-sizing: border-box;
    }
  }
}

.advantages {
  & :global {
    .product-advantages__item {
      align-items: center;
    }
  }
}

.text {
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.link {
  display: inline-flex;
}

@media (--screen-lg) {
  .root {
    & :global {
      .modal__panel {
        gap: 24px;
        max-width: 580px;
      }
    }
  }
}

@media (--screen-xl) {
  .root {
    & :global {
      .modal__panel {
        max-width: 564px;
      }
    }
  }
}
