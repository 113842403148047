@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--default-grey-30, #d6d6d6);
}

.item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 16px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--default-grey-30, #d6d6d6);
  }
}

.link {
  display: flex;
  gap: 16px;
  align-items: center;
}

.icon {
  flex-shrink: 0;
  margin-left: auto;
}

@media (--screen-xs) {
  .item {
    gap: 4px;
  }
}
