@import '../../../../shared/styles/core.css';

.button {
  min-height: 32px;
  padding: 4px 12px;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 16px;
  background: var(--priority-primary-20, #eef0fb);

  & .button {
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

@media (--screen-xs) {
  .button {
    min-height: 40px;
    padding: 6px 12px;
  }
}

@media (--screen-lg) {
  .root {
    max-width: 882px;
    padding: 32px 40px;
  }

  .button {
    min-height: 44px;
  }
}

@media (--screen-xl) {
  .root {
    max-width: 1005px;
    padding: 32px 40px;
  }
}
