@import '../../../../../../styles/core';

.regionConfirm {
  min-width: 282px;
  min-height: 92px;
  background: var(--colorWhite);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0px 1px 4px 0px #121C2940;

  &:before {
    content: ' ';
    position: absolute;
    top: -7px;
    right: 130px;
    background: var(--colorWhite);
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    box-shadow: -2px -2px 3px -2px #121C2940;
  }

  @media (--tablet) {
    &:before {
      display: none;
    }
  }
}

.wrapper {
  width: 88%;
  padding: 19px 0;
}

.question {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #656571;
}

.name {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #656571;
}

.answer {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  width: 100%;
}

.regionConfirm {
  .confirmButton {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--colorWhite);
    width: 100px;
    min-height: 25px;
    padding: 0 10px;
    cursor: pointer;
    background: linear-gradient(90deg, var(--colorPrimary) 1%, rgb(25 30 128 / 75%) 0);
    background-position: 0 0;
    animation: background 11s;
    border: none;
    backface-visibility: hidden;
    perspective: 1px;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  @keyframes background {
    from {
      background-size: 100%
    }
    to {
      background-size: 10000%
    }
  }

  .changeButton {
    background: #D9DADE;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #49494D;
    width: 100px;
    min-height: 25px;
    padding: 0 10px;
    border: none;
    cursor: pointer;

    &:hover {
      background: var(--colorBorderDefault);
      color: #49494D;
      border: none;
    }
  }
}

