@import '../../../../../../styles/core.css';


.defaultLogo {
  width: 150px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;

  @media (--desktopSmall) {
    width: 72px;
    height: 24px;
  }

  & svg {
    width: 100%;
    height: 100%;
  }
}

.headerNav {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--colorBorderDefault);
  background-color: var(--colorWhite);
  position: relative;

  @media (--desktopSmall) {
    background-color: var(--colorWhite);
    border-bottom: 1px solid #F0F4F8;
  }

  & .headerNavContent {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1538px;
    padding: 0;
    box-sizing: border-box;

    @media (--desktopLarge) {
      padding: 0 75px;
    }

    @media (--desktop) {
      padding: 0 48px;
    }

    @media (--desktopSmall) {
      padding: 0 7px;
      height: 40px;
    }

    @media (--tablet) {
      padding: 0 7px;
      height: 40px;
    }
  }

  & .headerNavGroup {
    display: flex;
    align-items: center;
    position: relative;
  }

  & .headerNavLogoTabletWrapper {
    @media (--tablet) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
    }
  }

  & .headerNavLogo {
  }

  & .headerNavLogoTablet {
    @media (--desktopSmall) {
      margin-left: 20px;
    }
  }

  & .headerNavMenuButton {
    border: none;
    outline: none;
    box-sizing: border-box;
    margin: 0;
    background-color: transparent;
    padding: 10px 8px 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--newColorTextSecondary);

    & svg {
      width: 24px;
      height: 24px;
      fill: var(--newColorTextSecondary);
      stroke: var(--newColorTextSecondary);
    }

    & svg path {
      stroke: var(--newColorTextSecondary);
    }
  }

  & .headerNavLogoText {
    margin: 0 0 0 40px;
    font-style: italic;
  }

  & .headerNavPhone {
    margin: 0 15px;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: var(--colorHover);
    }
  }

  & .headerNavLinkIcon {
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--colorIconAccent);
    margin: 0 8px 0 0;
  }

  & .headerNavShopLink {
    margin: 0 0 0 30px;
    align-items: center;
    cursor: pointer;

    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 0;
    background-color: transparent;
    text-decoration: none;

    display: none;

    @media (--desktopSmall) {
      display: flex;
    }
    @media (--tablet) {
      display: none;
    }

    &:hover {
      color: var(--colorHover);
    }
  }

  & .headerNavRegionLink {
    display: flex;
    align-items: center;
    cursor: pointer;

    border: none;
    outline: none;
    box-sizing: border-box;
    margin: 0 0 0 25px;
    padding: 0;
    background-color: transparent;

    display: none;

    @media (--desktopSmall) {
      display: flex;
    }
    @media (--tablet) {
      display: none;
    }

    & svg {
      fill: var(--colorTextSecondary);
    }

    &:hover {
      color: var(--colorHover);

      & svg {
        fill: var(--colorHover);
      }
    }
  }

  & .headerNavRegularLink {
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;

    & svg {
      top: 1px;
      padding: 0;
      margin: 0 0 0 4px;
      fill: var(--colorIconAccent);
    }

    &:hover {
      color: var(--colorHover);

      & svg {
        fill: var(--colorHover);
      }
    }

    & + .headerNavRegularLink {
      margin-left: 20px;
    }

  }

  & .desktopLarge {
    @media (--desktopSmall) {
      display: none;
    }
    @media (--tablet) {
      display: none;
    }
  }

  & .desktopSmall {
    display: none;

    @media (--desktopSmall) {
      display: block;
    }
    @media (--tablet) {
      display: none;
    }
  }

  & .tablet {
    display: none;

    @media (--desktopSmall) {
      display: none;
    }
    @media (--tablet) {
      display: block;
    }
  }

  & .notTablet {
    @media (--tablet) {
      display: none;
    }
  }

  & .notDesktopLarge {
    display: none;

    @media (--desktopSmall) {
      display: block;
    }
  }

  & .headerNavPhoneButton {
    & svg {
      width: 16px;
      height: 16px;

      @media (--desktopSmall) {
        width: 24px;
        height: 24px;
        color: var(--newColorTextSecondary);
      }
    }

    @media (--tablet) {
      order: 2;
    }
  }

  & .headerNavPointButton {
    & svg {
      width: 16px;
      height: 16px;

      @media (--desktopSmall) {
        width: 24px;
        height: 24px;
        color: var(--newColorTextSecondary);
      }
    }

    @media (--tablet) {
      order: 1;
    }
  }

  & .headerNavSearchButton {
    & > button > div > svg {
      width: 16px;
      height: 16px;

      @media (--desktopSmall) {
        width: 24px;
        height: 24px;
        color: var(--newColorTextSecondary);
      }
    }
  }

  & .headerNavFavButton {
    & svg {
      width: 20px;
      height: 20px;

      @media (--desktopSmall) {
        width: 24px;
        height: 24px;
        color: var(--newColorTextSecondary);
      }
    }

    @media (max-width: 375px) {
      display: none;
    }
  }

  & .headerNavBasketButton {
    & svg {
      width: 24px;
      height: 24px;

      @media (--desktopSmall) {
        width: 24px;
        height: 24px;
        color: var(--newColorTextSecondary);
      }
    }
  }

  & .headerNavCabinetButton {
    & svg {
      width: 19px;
      height: 18px;
    }
  }

  & .headerNavProfileButton {
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}

.regionConfirmNotTablet {
  top: -2px;
  left: 5px;
  position: absolute;
  z-index: 10;
  @media (--desktopSmall) {
    top: 62px;
    left: 169px;
  }
  @media (--tablet) {
    display: none;
  }
}

.regionConfirmTablet {
  display: none;
  z-index: 10;
  @media (--tablet) {
    display: block;
    position: absolute;
    top: 71px;
    left: auto;
    right: auto;
  }
}
