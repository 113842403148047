@import '../../styles/core.css';


.universalInlineForm {
  position: relative;

  & .universalInlineFormBody {
    margin: -15px -5px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    & > * {
      width: calc(50% - 10px);
      margin: 15px 5px;

      @media (--phoneLandscape) {
        width: 100%;
      }
    }

    @media (--phoneLandscape) {
      flex-direction: column;
    }
  }

  & .universalInlineFormContent {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;

    & > * + * {
      margin-top: 20px;
    }
  }

  & .universalInlineFormTitle {
    padding: 20px 30px 0 30px;
  }

  & .universalInlineFormDescription {
    margin-top: 20px;
    padding: 0 30px;
  }

  & .universalInlineFormField {
  }

  & .universalInlineFormButton {
  }

  & .universalInlineFormText {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #919196;
  }

  & .universalInlineFormLink {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;

    color: #919196;
  }

  & .universalInlineFormPlaceholder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.mini {
    & .universalInlineFormBody {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      & > * {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }

    & .universalInlineFormContent {
      padding: 15px;
    }

    & .universalInlineFormTitle {
      margin-top: 15px;
      padding: 0 15px;
    }

    & .universalInlineFormDescription {
      margin-top: 15px;
      padding: 0 15px;
    }
  }

  &.row {
    & .universalInlineFormBody {
      margin: -15px -5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;

      & > * {
        width: calc(33% - 10px);
        margin: 15px 5px;

        @media (--phoneLandscape) {
          width: 100%;
        }
      }

      @media (--phoneLandscape) {
        flex-direction: column;
        overflow: visible;
        align-items: center;
      }

      & input {
        border: 1px solid #C4C4C4;
      }

      & .row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    & .universalInlineFormText {
      margin-top: 12px;
    }

    & .universalInlineFormButton {
      width: 200px;
    }
  }
}
