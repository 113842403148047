@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 681px;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 14px;
  }
}

.list {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.link {
  display: inline-block;
  color: var(--priority-primary-80);
  text-decoration: underline;
}

.downloadLink {
  & :global {
    .link__body {
      display: flex;
      gap: 4px;
      align-items: center;
      padding: 0;
    }
  }
}

@media (--screen-lg) {
  .block {
    &:last-child {
      margin-bottom: 8px;
    }
  }

  .downloadLink {
    & :global {
      .link__body {
        gap: 8px;
      }
    }
  }
}

@media (--screen-xl) {
  .root {
    max-width: 1005px;
  }
}
