@import '../../styles/core.css';

.customButton {
  min-height: 48px;
  background: var(--colorPrimary);
  font: 700 16px/16px Manrope, sans-serif;
  box-sizing: border-box;
  padding: 16px 24px;
  color: var(--colorWhite);
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  transition: 0.3s all ease;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colorPrimary);
  border-radius: 0;

  @media (--tablet) {
    padding: 8px 12px;
  }

  &:hover {
    background: var(--colorHover);
    border: 1px solid var(--colorHover);
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }

  &:active {
    background: var(--colorHover);
    border: 1px solid var(--colorHover);
  }

  &:disabled {
    pointer-events: none;
    background: #e9e8e8;
    border-color: #e9e8e8;
  }

  &.solid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    background: var(--newColorPrimary);
    color: var(--colorWhite);
    font: 700 16px/24px Manrope, sans-serif;
    box-sizing: border-box;
    padding: 16px 0;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    transition: 0.2s all ease;
    outline: none;
    border: 1px solid var(--colorPrimary);
    border-radius: 4px;

    @media (--tablet) {
      padding: 8px 0;
    }

    &:hover {
      background: var(--colorHover);
      border: 1px solid var(--colorHover);
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: var(--colorHover);
      border: 1px solid var(--colorHover);
    }

    &:disabled {
      pointer-events: none;
      background: #e9e8e8;
      border-color: #e9e8e8;
    }
  }


  &.transparent {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid var(--colorPrimary);
    color: var(--colorPrimary);

    &:hover {
      background: var(--colorHover);
      color: var(--colorWhite);
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }


  &.transparentWhite {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    background: transparent;
    border: 1px solid var(--colorWhite);
    color: var(--colorWhite);

    &:hover {
      background: transparent;
      color: var(--colorWhite);
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }


  &.text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    border: 1px solid transparent;
    color: #191f80;

    &:hover {
      background: transparent;
      color: var(--colorHover);
      box-shadow: none;
    }
  }

  &.inverse {
    background: var(--colorPrimary);
    box-sizing: border-box;
    color: var(--colorWhite);
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease;
    outline: none;
    border: 1px solid var(--colorHover);
    border-radius: 0;

    &:hover {
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      background: transparent;
      border: 1px solid var(--colorHover);
      color: var(--colorHover);
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.highlight {
    align-items: center;
    justify-content: center;
    background: var(--colorAccent);
    border: none;
    color: #fff;

    &:hover {
      background: var(--colorAccent);
      color: #fff;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.medium {
    min-height: 40px;
    font-size: 12px;
    line-height: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &.wide {
    width: 100%;
  }

  &.widthMax {
    width: 100%;
  }

  &.redesign {
    background: var(--newColorPrimary);
    font: 700 20px/28px Manrope, sans-serif;
    box-sizing: border-box;
    padding: 12px 22px;
    color: var(--colorWhite);
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--newColorPrimary);
    border-radius: 4px;

    @media (--tablet) {
      padding: 8px 12px;
    }

    &.medium {
      font-size: 14px;
      line-height: 20px;
      padding: 14px 24px;
    }

    &:hover {
      background: var(--newColorPrimaryHover);
      border: 1px solid var(--newColorPrimaryHover);
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: var(--newColorPrimaryHover);
      border: 1px solid var(--newColorPrimaryHover);
    }

    &:disabled {
      pointer-events: none;
      background: var(--newColorPrimaryDisabled);
      border-color: var(--newColorPrimaryDisabled);
    }
  }
}
