@import '../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.lead {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  width: calc(100%);
}

.limiterControl {
  font-weight: 700;
}

@media (--screen-lg) {
  .root {
    gap: 40px;
  }
}
