@import '../../../../../../styles/core';

.headerHints {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--colorBackgroundPrimary);
  padding: 0;
  height: 40px;
  white-space: nowrap;

  @media (--desktopSmall) {
    display: none;
  }
  @media (--tablet) {
    display: none;
  }

  & .headerHintsContent {
    display: flex;
    justify-content: space-between;
    max-width: 1538px;
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    @media (--desktopLarge) {
      padding: 0 75px;
    }

    @media (--desktop) {
      padding: 0 48px;
    }

    @media (--desktopSmall) {
      padding: 0 40px;
    }

    @media (--tablet) {
      padding: 0 15px;
    }
  }

  & .headerHintsShopLinks {
    display: flex;
  }

  & .headerHintsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 0;

    & svg {
      height: 16px;
    }
  }

  & .headerHintsShopLink {
    display: flex;
    align-items: center;
    cursor: pointer;

    border: none;
    outline: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: transparent;
    text-decoration: underline;

    &:hover {
      color: var(--colorHover);
    }
  }

  & .headerHintsRegionLink {
    display: flex;
    align-items: center;
    cursor: pointer;

    border: none;
    outline: none;
    box-sizing: border-box;
    margin: 0 0 0 25px;
    padding: 0;
    background-color: transparent;

    & svg {
      fill: var(--colorTextSecondary);
    }

    &:hover {
      color: var(--colorHover);

      & svg {
        fill: var(--colorHover);
      }
    }
  }

  & .headerHintsFeatures {
    display: flex;
  }

  & .headerHintsFeature {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: var(--colorHover);

      & .headerHintsIcon {
        fill: var(--colorHover);
      }
    }

    & + .headerHintsFeature {
      margin-left: 16px;
    }
  }

  & .headerHintsReviews {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: 10px;
    text-decoration: none;

    & .headerHintsReviewIcon {
      margin: 0 8px 0 0;
      width: 19px;
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: var(--colorHover);
    }
  }
}
