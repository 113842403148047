@import '../../../../src/shared/styles/core.css';

.vr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin: 0 0 60px 0;
    box-sizing: border-box;
  }

  & .section {
    width: 100%;
    max-width: 985px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  & .sectionTitle {
    padding: 60px 24px;
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 67px;
    color: #000000;
    text-align: center;
    margin: 0;
    box-sizing: border-box;

    @media screen and (max-width: 1023px) {
      font-size: 38px;
      line-height: 53px;
    }

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  & .sectionSubtitle {
    padding: 60px 24px;
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 45px;
    color: #000000;
    text-align: center;
    box-sizing: border-box;

    @media screen and (max-width: 1023px) {
      font-size: 30px;
      line-height: 40px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  & .sectionDescription {
    padding: 60px 24px 30px 24px;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    color: #000000;
    box-sizing: border-box;

    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  & .sectionImage {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 148px;

    @media (--s-xs) {
      height: 196px;
    }

    @media (--s-sm) {
      height: 320px;
    }

    @media (--s-md) {
      height: 360px;
    }

    @media (--s-lg) {
      height: 500px;
    }

    & img {
      max-width: 985px;
      height: 124px;

      @media (--s-xs) {
        height: 160px;
      }

      @media (--s-sm) {
        height: 240px;
      }

      @media (--s-md) {
        height: 240px;
      }

      @media (--s-lg) {
        height: 370px;
      }
    }
  }

  & .slider {
    @media screen and (max-width: 1023px) {
      height: 500px;
    }

    @media screen and (max-width: 767px) {
      height: 209px;
    }
  }

  & .sectionPoint {
    max-width: 96px;
    background-color: #F7F6F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    color: #000000;
    box-sizing: border-box;


  }

  & .sectionPointWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  & .sectionActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 0 0;
  }

  & .sectionAction {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  & .sectionLine {
    height: 60px;


    @media screen and (max-width: 767px) {
      height: 30px;
    }
  }

  & .desktop {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  & .mobile {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
    }

    .section {
      width: 100%;
    }

    .sectionImage img {
      width: 100%;
      max-width: none;
    }
  }

  & iframe {
    width: 985px;
    height: 505px;
    max-width: calc(100% - 64px);
    aspect-ratio: 16/9;

    @media screen and (max-width: 1023px) {
      width: 548px;
      height: 280px;
    }

    @media screen and (max-width: 767px) {
      width: 400px;
      height: 300px;
    }

    @media screen and (max-width: 539px) {
      width: 400px;
      height: 300px;
    }
  }
}
