@import '../../styles/core.css';

.root {
  gap: 0;
}

@media (--screen-xs) {
  .root {
    & .downloadIcon {
      display: none;
    }

    & .nextIcon {
      display: none;
    }
  }
}
