

.blogArticleImage {
    width: 100%;
    height: calc(80vw / 4 * 3);
    text-align: center;
    position: relative;

    @media (min-width: 476px) {
        height: 300px;
    }

    @media (min-width: 769px) {
        height: 300px;
    }

    @media (min-width: 976px) {
        height: 600px;
    }
}

.blogArticleDetailLayout {
    & .blogArticleDetailLayoutInner {
        max-width: 1320px;
        margin: 0 auto 50px;
        box-sizing: border-box;
        @media screen and (max-width: 1020px) {
            padding: 0 10px;
        }
    }

    & .blogArticleDetailLayoutNavigationBlock {
        display: flex;
        margin-bottom: 70px;
        margin-top: 32px;
        justify-content: center;
        align-items: baseline;

        & a {
            color: #87878f;
            font-size: 12px;

            &:hover {
                color: #191E80;
            }
        }

        & span {
            margin: 0px 15px;
        }

        & div,
        & span {
            color: #87878f;
            cursor: default;
        }
    }

    & .blogArticleDetailLayoutHeaderBlock {
        margin: 0px 0px 40px;

        & .blogArticleDetailLayoutHeaderBlockTitle {
            font-size: 32px;
            max-width: 800px;
            margin: 0 auto 0px;
            text-align: center;
            font-family: Manrope, system-ui, sans-serif;
            font-weight: 400;

            @media (max-width: 767px) {
                margin-top: 16px;
                scroll-margin-top: 51px;
            }
        }

        & .blogArticleDetailLayoutHeaderBlockDate {
            max-width: 800px;
            margin: 0 auto 40px;
            color: #68686e;
            text-align: center;
            font: 14px / 20px Manrope, sans-serif;
            letter-spacing: 1.4px;
            text-transform: uppercase;
        }

        & .blogArticleDetailLayoutHeaderBlockPreviewImage {
            width: 100%;
            height: calc(80vw / 4 * 3);
            text-align: center;
            position: relative;

            @media (min-width: 476px) {
                height: 300px;
            }

            @media (min-width: 769px) {
                height: 300px;
            }

            @media (min-width: 976px) {
                height: 600px;
            }
        }
    }

    & .blogArticleDetailLayoutCategoryList {
        width: 100%;
        display: flex;

        @media screen and (max-width: 778px) {
            flex-direction: column;
        }

        & .blogArticleDetailLayoutCategoryListItem {
            width: calc(25% - 22px);
            margin: 10px 10px 0;
            transition: all 0.3s;
            box-shadow: 0 1px 1px 0 rgba(209, 209, 209, 0.3), 0 1px 1px 0 rgba(209, 209, 209, 0.3);

            @media screen and (max-width: 778px) {
                width: 100%;
                margin: 10px 0px;
            }

            &:hover {
                box-shadow: 0 7px 10px 0 rgba(163, 163, 163, 0.21), 0 1px 1px 0 rgba(209, 209, 209, 0.3);
            }

            & a {
                padding: 15px 25px;
                display: flex;

                & svg {
                    flex-shrink: 0;
                }

                & span {
                    transition: all 0.3s;
                    margin: auto 0 auto 20px;
                    color: #3a353c;
                    font-weight: 700;
                }

                &:hover {
                    & span {
                        color: #191E80;
                    }
                }
            }
        }
    }

    & .blogArticleDetailLayoutContent {
        max-width: 800px;
        margin: 0 auto 45px;
    }

    & .blogArticleDetailLayoutContentShareAndTags {
        display: flex;
        width: 100%;
        margin-top: 38px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            text-align: center;
        }
    }

    & .blogArticleDetailLayoutContentShare,
    & .blogArticleDetailLayoutContentTags {
        & .blogArticleDetailLayoutContentShareTitle,
        & .blogArticleDetailLayoutContentTagsTitle {
            margin-bottom: 12px;
            font: 14px / 20px Manrope, sans-serif;
            letter-spacing: 1.4px;
            text-transform: uppercase;
        }
    }

    & .blogArticleDetailLayoutContentTags {
        & .blogArticleDetailLayoutContentTagsList {
            display: flex;
            flex-wrap: wrap;

            & .blogArticleDetailLayoutContentTagsListItem {
                cursor: pointer;
                display: block;
                color: #68686e;
                font-size: 12px;
                padding: 6px 9px;
                border: 1px solid rgba(104, 104, 110, 0.3);
                border-radius: 0;
                background: transparent;
                transition: all 0.3s;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0px;
                }

                &:hover {
                    & a {
                        color: #ffffff;
                    }

                    background: #191E80;
                    color: #ffffff;
                    border-radius: 2px;
                }

                & a {
                    color: #68686e;
                }
            }

            @media screen and (max-width: 778px) {
                display: block;
                & .blogArticleDetailLayoutContentTagsListItem {
                    display: inline-block;
                }
            }
        }
    }

    & .blogArticleDetailLayoutContentShare {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 778px) {
            margin-bottom: 20px;
        }

        & .blogArticleDetailLayoutContentShareButtons {
            display: flex;
        }

        & svg {
            width: 15px;
            margin: 6px;
        }

        & .blogArticleDetailLayoutContentShareButtonsOk,
        & .blogArticleDetailLayoutContentShareButtonsVk,
        & .blogArticleDetailLayoutContentShareButtonsFb,
        & .blogArticleDetailLayoutContentShareButtonsTwitter {
            cursor: pointer;
            height: 28px;
            margin-right: 10px;
            padding-right: 8px;
            border-radius: 3px;
            @media screen and (max-width: 778px) {
                display: inline-block;
            }
        }

        & .blogArticleDetailLayoutContentShareButtonsOk {
            background: #f59310;
        }

        & .blogArticleDetailLayoutContentShareButtonsVk {
            background: #587e9f;
        }

        & .blogArticleDetailLayoutContentShareButtonsFb {
            background: #3d5b95;
        }

        & .blogArticleDetailLayoutContentShareButtonsTwitter {
            background: #24aadd;
        }
    }

    & .blogArticleDetailLayoutContentTags {
        width: 50%;
        @media screen and (max-width: 778px) {
            width: 100%;
            text-align: center;
        }
    }

    & .blogArticleDetailLayoutContentInfo {
        display: flex;
        box-shadow: 0 4px 5px 0 transparent;
        transition: all 0.3s;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        width: 100%;

        @media screen and (max-width: 778px) {
            flex-direction: column;
        }

        & .blogArticleDetailLayoutContentInfoText {
            width: 65%;
            padding: 30px 45px 35px;
            background: #F7F6F5;

            @media screen and (max-width: 778px) {
                width: 100%;
                text-align: center;
                padding: 30px 15px 35px;
            }

            & .blogArticleDetailLayoutContentInfoTextTitle {
                font-size: 18px;
                line-height: 30px;
                font-weight: 700;
                color: #49494D;
                transition: all 0.3s;

                &:hover {
                    color: #191E80;
                }
            }

            & .blogArticleDetailLayoutContentInfoTextButton {
                margin-top: 10px;
            }
        }

        & .blogArticleDetailLayoutContentInfoImage {
            width: 45%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media screen and (max-width: 778px) {
                width: 100%;
                height: 180px;
            }
        }

        &:hover {
            box-shadow: 0 4px 5px 0 rgba(64, 64, 64, 0.1), 0 1px 1px 0 rgba(209, 209, 209, 0.3);
        }
    }


    & .blogArticleDetailLayoutContentMoreInfo {
        max-width: 800px;
        margin: 0 auto 80px;
    }

    & .blogArticleDetailLayoutReadMore {
        & .blogArticleDetailLayoutReadMoreTitle {
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding-bottom: 12px;
            text-align: left;
            font-size: 16px;
            color: #000;
            margin-bottom: 25px;
            font-weight: 700;
        }

        & .blogArticleDetailLayoutReadMoreList {
            width: 100%;
            display: flex;
            margin-bottom: 60px;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                margin-bottom: 15px;
            }

            & .blogArticleDetailLayoutReadMoreListShowMoreButton {
                width: 100%;
                margin: 70px 0px;

                & .blogArticleDetailLayoutReadMoreListShowMoreButtonText {
                    max-width: 330px;
                    transition: all 0.3s;
                    font-size: 19px;
                    text-align: center;
                    box-sizing: border-box;
                    border: 4px solid;
                    height: 88px;
                    line-height: 81px;
                    font-weight: 700;
                    color: #49494D;
                    margin: 0 auto;
                    cursor: pointer;

                    &:hover {
                        color: #191E80;
                    }
                }
            }

            & .blogArticleDetailLayoutReadMoreListItem {
                margin-top: 20px;
                width: 32%;
                background: #F7F6F5;
                box-shadow: 0 4px 5px 0 transparent;
                transition: all 0.3s;

                &:last-child {
                    margin-right: 0;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                &:hover {
                    box-shadow: 0 4px 5px 0 rgba(64, 64, 64, 0.1), 0 1px 1px 0 rgba(209, 209, 209, 0.3);

                    & .blogArticleDetailLayoutReadMoreListItemImageHoverBlock {
                        opacity: 1;
                    }
                }

                & .blogArticleDetailLayoutReadMoreListItemImage {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    height: 240px;

                    & .blogArticleDetailLayoutReadMoreListItemImageHoverBlock {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.3);
                        transition: all 0.3s;
                        cursor: pointer;
                    }

                    & .blogArticleDetailLayoutReadMoreListItemImageButton {
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #ffffff;
                        padding: 6px 10px;
                        border: 1px solid;
                        transition: all 0.3s;
                    }
                }

                & .blogArticleDetailLayoutReadMoreListItemContent {
                    padding: 25px 25px 35px;

                    & .blogArticleDetailLayoutReadMoreListItemContentText {
                        display: block;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 25px;
                        margin-bottom: 10px;
                        color: #49494D;
                    }

                    & .blogArticleDetailLayoutReadMoreListItemContentDate {
                        font-size: 12px;
                        color: #68686e;
                    }
                }
            }
        }
    }
}
