@import '../../../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.picture {
  flex-shrink: 0;
}

@media (--screen-lg) {
  .root {
    flex-direction: row;
    gap: 24px;
  }

  .item {
    align-items: flex-start;
  }
}
