@import '../../styles/core.css';

.breadcrumbs {
    display: flex;
    position: relative;
    margin: 8px -8px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    color: var(--colorTextSecondary);

    @media (--tablet) {
        overflow: hidden;
        flex-wrap: nowrap;
    }

    &.padded {
        padding: 0 16px;
    }


    & .breadcrumbsCollapseButton {
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--colorPrimary);
        align-items: center;
        display: none;
        padding: 6px 18px 6px 10px;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        border-bottom: 1px dotted transparent;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 1) 100%);

        @media (--tablet) {
            display: flex;
            position: absolute;
            right: -8px;
            top: 0;
            font-size: 12px;
            line-height: 12px;
        }

        &:hover {
            color: var(--colorHover);
        }

        & > svg {
            margin: 0 0 0 5px;
        }
    }

    &.expanded {
        @media (--tablet) {
            flex-wrap: wrap;

            & .breadcrumbsCollapseButton {
                position: static;
                top: auto;
                right: auto;
                background: none;
            }
        }
    }

}

.breadcrumbsItemWrapper {
    margin: 0 8px;
    padding: 6px 0;
    display: inline;
    max-width: 100%;
    position: relative;
}

.breadcrumbsItem {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px dotted transparent;

    color: #969696;

    @media (--tablet) {
        font-size: 12px;
        line-height: 12px;
    }

    &:not(.disabled):hover {
        border-bottom: 1px dotted #979797;
    }

    &.disabled {
        opacity: 0.6;
        cursor: auto;
    }

    &.expandable {
        border-bottom: 1px dotted #979797;
    }
}

.breadcrumbsAngle {
    width: 5px;
    height: 5px;
    border: 1px solid transparent;
    border-bottom-color: #87878F;
    border-right-color: #87878F;
    background: transparent;
    transform: rotate(-45deg);
    flex-shrink: 0;

    @media (max-width: 767px) {
        width: 5px;
        height: 5px;
    }
}

.breadcrumbsToggleAngle {
    margin: 0 0 0 4px;
}

.breadcrumbsPopup {
    margin: 0;
    z-index: 10;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    display: none;
    width: 340px;
    max-width: calc(100vw - 32px);
    background-color: var(--colorWhite);
    border: 1px solid var(--colorBorderDefault);
    box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: auto;

    &.expanded {
        display: block;
    }

    & .breadcrumbsPopupWrapper {
        width: 100%;
    }

    & .breadcrumbsPopupHeader {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--colorBorderDefault);
    }

    & .breadcrumbsPopupClose {
        outline: none;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        cursor: pointer;

        & svg path {
            stroke: var(--colorTextPrimary);
        }

        &:hover {
            & svg path {
                stroke: var(--colorHover);
            }
        }
    }

    & .breadcrumbsPopupContent {
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
    }

    & .breadcrumbsPopupContentItem {
        width: calc(50% - 5px);
        margin: 0 5px 5px 0;

        @media (--tablet) {
            width: calc(100% - 5px);
        }

        & * {
            color: var(--colorPrimary);
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        &:hover {
            & * {
                color: var(--colorHover);
            }
        }
    }
}
