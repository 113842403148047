@import '../../styles/core.css';


.form {
    background: var(--colorBackgroundPrimary) url('/static/imgs/common/form-pattern-light.svg') no-repeat left center;
}

.formCarpetsToOrder {
    background: var(--colorBackgroundSecondary) url('/static/imgs/customCarpets/form-icon.svg') no-repeat;
    background-position: -18px -81px;
    background-size: 400px 400px;

    @media (--phoneLandscape) {
        background-position: center -58px;
        background-size: 500px 500px;
    }
}
