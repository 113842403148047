/* stylelint-disable font-family-name-quotes */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable order/properties-order */
/* stylelint-disable custom-property-pattern */
@import '../../styles/core';

.typography {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.markdown {
  font-family: Manrope, sans-serif;

  & pre,
  & code {
    font-family: Menlo, Monaco, monospace;
  }

  & pre {
    padding: 0.5rem;
    line-height: 1.25;
    overflow-x: scroll;
    box-sizing: border-box;
  }

  & a:not(.customComponent) {
    color: var(--colorPrimary);
    text-decoration: none;

    &:visited {
      color: inherit;
    }

    &:hover {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--colorHover);
    }
  }

  & ul,
  & ol {
    margin: 16px 0;
  }

  & p {
    margin: 16px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: var(--colorTextPrimary);
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 24px 0;
    padding: 0;
    line-height: 43px;
    box-sizing: border-box;
  }

  & h1 {
    color: var(--colorTextPrimary);

    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;

    @media (--tabletLandscape) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & h2 {
    color: var(--colorTextPrimary);

    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;

    @media (--tabletLandscape) {
      font-size: 22px;
      line-height: 35px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & h3 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & h4 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    @media (--tabletLandscape) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (--tablet) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h5 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & h6 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & small {
    font-size: 12px;
  }

  & img,
  & canvas,
  & iframe,
  & video,
  & svg,
  & select,
  & textarea {
    max-width: 100%;
  }

  & blockquote {
    border-left: 8px solid #fafafa;
    padding: 1rem;
    box-sizing: border-box;
  }

  & pre,
  & code {
    background-color: #fafafa;
  }

  & ul {
    padding: 0 0 0 20px;
    margin: 16px 0;
    box-sizing: border-box;
  }

  & .markdownContents {
    width: 100%;

    & > ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;
      width: 100%;
      font-size: 16px;
      box-sizing: border-box;

      & > li {
        width: calc(33.3% - 40px);
        list-style: none;
        position: relative;
        margin: 0 0 25px 40px;

        & > .title {
          display: inline;
          text-decoration: underline;
          color: var(--colorTextPrimary);
          font-weight: 400;

          &.link {
            cursor: pointer;
          }
        }

        & > .number {
          position: absolute;
          right: calc(100%);
          top: 0;
          color: var(--colorPrimary);
          display: inline;
          margin: 0 10px 0 0;

          text-align: end;
          font-family: Manrope, system-ui, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    & ul {
      padding: 0;
      font-size: 14px;
      box-sizing: border-box;

      & > li {
        list-style: none;
        margin-bottom: 15px;

        & .title {
          color: var(--colorPrimary);
          font-weight: 400;

          &.link {
            cursor: pointer;

            &:visited {
              color: var(--colorPrimary);
            }

            &:hover {
              text-decoration: underline;
            }

            &:hover,
            &:focus,
            &:active {
              color: var(--colorHover);
            }
          }
        }
      }
    }
  }

  & .markdownTypography {
    &.body1 {
      color: var(--colorTextPrimary);

      font-family: Manrope, system-ui, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      @media (--tabletLandscape) {
        font-size: 14px;
        line-height: 18px;
      }

      @media (--tablet) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &.body2 {
      color: var(--colorTextSecondary);

      font-family: Manrope, system-ui, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;

      @media (--tabletLandscape) {
        font-size: 13px;
        line-height: 16px;
      }

      @media (--tablet) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    &.body3 {
      color: var(--colorTextSecondary);

      font-family: Manrope, system-ui, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      @media (--tabletLandscape) {
        font-size: 12px;
        line-height: 16px;
      }

      @media (--tablet) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.bold {
      font-weight: 700;
    }

    &.primary {
      color: var(--colorTextPrimary);
    }

    &.secondary {
      color: var(--colorTextSecondary);
    }

    &.neutral {
      color: var(--colorTextNeutral);
    }
  }

  &.singleFontMode {
    & h1 {
      font-family: Manrope, sans-serif;
    }

    & h2 {
      font-family: Manrope, sans-serif;
    }
  }
}

.promoMarkdown {
  font-family: Manrope, sans-serif;

  & pre,
  & code {
    font-family: Menlo, Monaco, monospace;
  }

  & pre {
    padding: 0.5rem;
    line-height: 1.25;
    overflow-x: scroll;
    box-sizing: border-box;
  }

  & a:not(.customComponent) {
    color: var(--colorPrimary);
    text-decoration: none;

    &:visited {
      color: inherit;
    }

    &:hover {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--colorHover);
    }
  }

  & ul,
  & ol {
    margin: 16px 0;
  }

  & p {
    margin: 16px 0;
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  & h1,
  & h2,
  & h3 {
    margin: 24px 0;
    padding: 0;
    box-sizing: border-box;
  }

  & h1 {
    color: #212121;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;

    @media (--tabletLandscape) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & h2 {
    color: #212121;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Playfair Display', serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;

    @media (--tabletLandscape) {
      font-size: 22px;
      line-height: 35px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & h3 {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & .caption {
    color: #212121;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & .label {
    color: #212121;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.48px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & small {
    font-size: 12px;
  }

  & img,
  & canvas,
  & iframe,
  & video,
  & svg,
  & select,
  & textarea {
    max-width: 100%;
  }

  & blockquote {
    border-left: 8px solid #fafafa;
    padding: 1rem;
    box-sizing: border-box;
  }

  & pre,
  & code {
    background-color: #fafafa;
  }

  & ul {
    padding: 0 0 0 20px;
    margin: 16px 0;
    box-sizing: border-box;
  }
}
