@import '../Description.module.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.limiter {
  & :global {
    .limiter__content {
      &::after {
        bottom: -13px;
      }
    }
  }
}

.control {
  min-height: 40px;
  margin-top: -5px;
  margin-bottom: 14px;
  font-weight: 700;
}

@media (--screen-sm) {
  .control {
    min-height: 60px;
  }
}

@media (--screen-lg) {
  .root {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;
  }

  .title {
    grid-column: 1/-1;
  }

  .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 16px;
    grid-column-gap: unset;
  }
}

@media (--screen-xl) {
  .root {
    grid-row-gap: 16px;
    grid-column-gap: 24px;
  }
}
