/* stylelint-disable custom-property-pattern */

@import '../../../../../styles/core.css';

.root {
  & .tabsItems {
    margin: 20px 0 0;
    background-color: var(--colorBackgroundPrimary);
  }
}

.header {
  margin: 56px 0 0;
}

.content {
  margin: 50px 0 0;
}

.tabsHeader {
  display: grid;
  grid-template-areas:
    'color shape size price link'
    'panel panel panel panel panel';
  grid-template-columns: repeat(5, auto);
  grid-row-gap: 20px;
  width: 100%;
}

.tabColor {
  grid-area: color;
}

.tabShape {
  grid-area: shape;
}

.tabSize {
  grid-area: size;
}

.tabPrice {
  grid-area: price;
}

.tabPanel {
  grid-area: panel;
}

.tabLink {
  grid-area: link;
}

.tabsItem {
  display: none;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 20px;
  background-color: var(--colorBackgroundSecondary);

  &.selected {
    display: flex;
  }
}

.tabsItemHeader {
  flex-shrink: 0;
  width: calc(100% - 450px);
  margin: -16px 0;
  padding: 24px 40px 24px 0;
}

.tabsItemContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tabsItemButton {
    align-self: flex-end;
    margin: 40px 0 0;
    white-space: normal;

    & svg {
      margin: 0 0 0 15px;
      stroke: var(--colorWhite);
    }
  }
}

.tabsItemFilter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 380px;
  margin: -5px;

  @media (max-width: 768px) {
    max-height: 640px;
  }

  & .tabsItemFilterItem {
    display: inline-flex;
    margin: 5px;
  }
}

.tabsItemColorFilter {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  gap: 12px;

  & .tabsItemColorFilterItem {
    appearance: none;
    width: 40px;
    height: 40px;
    margin: 5px;
    border: 3px solid var(--colorBorderDefault);
    background-position: center;
    background-size: 200% 200%;
    background-repeat: no-repeat;
    outline: 3px solid transparent;
    cursor: pointer;

    &:hover {
      outline: 3px solid var(--colorHover);
    }

    &.selected {
      outline: 3px solid var(--colorPrimary);
    }
  }
}

.tabsHeaderButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px 6px;
  border: 1px solid var(--colorBackgroundSecondary);
  background-color: var(--colorBackgroundSecondary);
  color: var(--colorPrimary);
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  font-family: Manrope, system-ui, sans-serif;
  line-height: 24px;
  white-space: nowrap;
}

.tabsHeaderButton {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px;
  border: 1px solid var(--colorBorderDefault);
  background-color: var(--colorBackgroundSecondary);
  outline: none;
  cursor: pointer;

  & + .tabsHeaderButton {
    margin-left: -1px;
  }

  & .icon {
    flex-shrink: 0;
    margin: 0 10px 0 0;
  }

  &:visited {
    border: 1px solid var(--colorBorderDefault);
    background-color: var(--colorBackgroundSecondary);
    color: var(--colorPrimary);

    & .tabsHeaderButtonContent {
      border: 1px solid var(--colorBackgroundSecondary);
      background-color: var(--colorBackgroundSecondary);
      color: var(--colorPrimary);
    }
  }

  &:hover {
    border: 1px solid var(--colorHover);
    background-color: var(--colorHover);
    color: var(--colorWhite);

    & .tabsHeaderButtonContent {
      border: 1px solid var(--colorHover);
      background-color: var(--colorHover);
      color: var(--colorWhite);
    }
  }

  &.selected {
    border: 1px solid var(--colorPrimary);
    background-color: var(--colorPrimary);
    color: var(--colorWhite);

    & .tabsHeaderButtonContent {
      border: 1px solid var(--colorWhite);
      background-color: var(--colorPrimary);
      color: var(--colorWhite);
    }
  }
}

.tabsHeaderButtonContentArrow {
  display: none;
  margin: 0 0 0 10px;
}

@media (max-width: 1280px) {
  .content {
    margin: 30px 0 0;
  }

  .tabsHeaderButton {
    margin-bottom: 10px;
  }

  .tabsHeaderButtonContent {
    padding: 18px;
  }

  .tabsHeader {
    grid-template-areas:
      'color shape size'
      'panel-first panel-first panel-first'
      'price link .'
      'panel-second panel-second panel-second';
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-row-gap: initial;
    grid-column-gap: 20px;
    background-color: transparent;
  }

  .tabPanel {
    grid-area: panel-first;

    @media (max-width: 1024px) {
      grid-area: panel-second;
    }
  }

  .tabsItem {
    background-color: transparent;
  }
}

@media (max-width: 1024px) {
  .tabsHeader {
    grid-template-areas:
      'color shape'
      'panel-first panel-first'
      'size price'
      'panel-second panel-second'
      'link .';
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .tabsItem {
    flex-direction: column;
    background-color: var(--colorBackgroundSecondary);
  }

  .tabsHeader {
    display: flex;
    flex-direction: column;
    grid-template-areas: unset;
    background-color: transparent;
  }

  .tabsItemHeader {
    width: 100%;
    padding: 24px 0;
  }

  .tabsItemContent {
    margin: 20px 0 0;
  }

  .tabsItemButton {
    align-self: flex-start;
  }

  .tabsHeaderButtonContent {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .tabsHeaderButtonContentArrow {
    display: inline-flex;
  }

  .tabsHeaderButton {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.selected {
      & .tabsHeaderButtonContent {
        border: 1px solid var(--colorPrimary);
      }
    }
  }
}
