@import '../../styles/core';

.formPlaceholder {
    width: 100%;
    height: 100%;
    position: relative;
    background: #F5F5F5 url('/static/imgs/abstract.svg') no-repeat bottom right;
    background-size: contain;


    & .formPlaceholderContent {
        padding: 20px;
        display: block;
    }

    & .formPlaceholderButton {
        margin-top: 50px;
    }

    &.spaced {
        & .formPlaceholderContent {
            & > * + p {
                margin-top: 15px;
            }
        }
    }
}
