@import '../../../../../shared/styles/core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: grid;
  grid-template-areas:
    'icon title'
    '.    description';
  grid-template-columns: 32px minmax(0, 1fr);
  grid-column-gap: 12px;
}

.icon {
  display: flex;
  grid-area: icon;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 2px;
}

.title {
  grid-area: title;
  margin-bottom: 4px;
}

.description {
  grid-area: description;
  margin: 0;
  padding: 0;
}

@media (--screen-lg) {
  .list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 24px;
    grid-column-gap: 60px;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .description {
    margin-top: -12px;
  }
}

@media (--screen-xl) {
  .list {
    grid-column-gap: 24px;
  }
}
