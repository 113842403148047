
.notFound {
    padding: 128px 24px;
}

.notFoundContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notFoundImage {

}

.notFoundText {
    font-size: 14px;
    line-height: 20px;
    color: #68686E;
}

.notFoundButton {
    padding: 24px;
    font-size: 24px;
}
