@import '../../styles/core.css';

.legacyTypography {
  margin: 0;
  padding: 0;

  &.h1 {
    color: var(--colorTextPrimary);

    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;

    @media (--tabletLandscape) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.h2 {
    color: var(--colorTextPrimary);

    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;

    @media (--tabletLandscape) {
      font-size: 22px;
      line-height: 35px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.h3 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.h4 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    @media (--tabletLandscape) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (--tablet) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.h5 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.h6 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {

    }
  }

  &.body1 {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.body2 {
    color: var(--colorTextSecondary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;

    @media (--tabletLandscape) {
      font-size: 13px;
      line-height: 16px;
    }

    @media (--tablet) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &.body3 {
    color: var(--colorTextSecondary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    @media (--tabletLandscape) {
      font-size: 12px;
      line-height: 16px;
    }

    @media (--tablet) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.button {
    color: var(--colorTextPrimary);

    font-family: Manrope, system-ui, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    outline: none;
    padding: 0;
    margin: 0;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.link {
    color: var(--colorPrimary);
  }

  &.warning {
    color: var(--colorNegative);
  }

  &.button {
    color: var(--colorPrimary);
  }

  &.bold {
    font-weight: 700;
  }

  &.semibold {
    font-weight: 600;
  }

  &.regular {
    font-weight: 400;
  }

  &.light {
    font-weight: 300;
  }

  &.primary {
    color: var(--colorTextPrimary);
  }

  &.secondary {
    color: var(--colorTextSecondary);
  }

  &.white {
    color: var(--colorWhite);
  }

  &.accent {
    color: var(--colorIconAccent);
  }

  &.neutral {
    color: var(--colorTextNeutral);
  }
}


.typography {
  margin: 0;
  padding: 0;

  &.serif {
    font-family: 'Playfair Display', serif;
  }

  &.sans {
    font-family: Manrope, sans-serif;
  }

  &.h0 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 40px;
    line-height: 48px;

    @media (--tabletLandscape) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.h1 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 36px;
    line-height: 48px;

    @media (--tabletLandscape) {
      font-size: 22px;
      line-height: 35px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.b1 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 24px;
    line-height: 32px;

    @media (--tabletLandscape) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.b2 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 20px;
    line-height: 28px;

    @media (--tabletLandscape) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (--tablet) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.b3 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 16px;
    line-height: 24px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.b4 {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 14px;
    line-height: 20px;

    @media (--tabletLandscape) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (--tablet) {

    }
  }

  &.caption {
    color: var(--newColorTextPrimary);

    font-style: normal;
    font-size: 10px;
    line-height: 16px;

    @media (--tabletLandscape) {
      font-size: 10px;
      line-height: 16px;
    }

    @media (--tablet) {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &.link {
    color: var(--newColorPrimary);
  }

  &.warning {
    color: var(--colorNegative);
  }

  &.button {
    color: var(--newColorPrimary);
  }

  &.strike {
    text-decoration: line-through;
  }

  &.bold {
    font-weight: 700;
  }

  &.semibold {
    font-weight: 600;
  }

  &.medium {
    font-weight: 500;
  }

  &.regular {
    font-weight: 400;
  }

  &.light {
    font-weight: 300;
  }

  &.primary {
    color: var(--newColorTextPrimary);
  }

  &.secondary {
    color: var(--newColorTextSecondary);
  }

  &.white {
    color: var(--colorWhite);
  }

  &.accent {
    color: var(--colorIconAccent);
  }

  &.neutral {
    color: var(--colorTextNeutral);
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
