@import '../../../../styles/core.css';

.header {
  width: 100%;
  z-index: 200;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.search {
  display: none;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--colorWhite);
  padding: 0;
  border-bottom: 1px solid var(--colorBorderDefault);

  @media (--desktopSmall) {
    display: flex;
    border-bottom: 1px solid #F0F4F8;
  }

  & .searchContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1538px;
    width: 100%;
    height: 48px;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    @media (--desktopSmall) {
      padding: 0 15px 0 7px;
      height: 40px;
    }

    @media (--tablet) {
      padding: 0 15px 0 7px;
      height: 40px;
    }
  }

  .searchClose {
    display: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    width: 48px;
    height: 16px;
    margin: 0 0 0 20px;
    flex-shrink: 0;
    color: var(--colorIconAccent);

    &:hover {
      color: var(--colorPrimary)
    }

    & svg {
      height: 18px;
      width: 18px;
    }
  }

  & .searchIcon {
    box-sizing: border-box;
    background-color: transparent;
    padding: 0 8px;
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 42px;
    height: 16px;
    flex-shrink: 0;
    fill: var(--colorIconAccent);
    flex-shrink: 0;
    color: var(--newColorTextSecondary);
  }

  & .searchInput {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 16px;
    line-height: 16px;
    color: var(--colorTextSecondary);
    font-family: Manrope, sans-serif;
    margin-left: -55px;
    padding-left: 55px;

    &::placeholder {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.5px;
      font-size: 16px;
      line-height: 16px;
    }

    &:not(:placeholder-shown) + .searchClose {
      display: flex;
    }
  }
}
